import React from "react"
import PropTypes from "prop-types"
import { isBrowser } from "../utils/browser"
import { formatHeapEvent } from "../utils/helpers"

const Button = ({
  buttonText,
  buttonType,
  handleOnClick,
  additionalClasses,
  disabled,
  buttonId,
  eventName,
  children,
  eventProperties,
  style,
  testId,
}) => {
  let heapAPI
  if (isBrowser() && window.heap) {
    heapAPI = window.heap
  }

  const onClickHandler = (event) => {
    // If no eventName object is passed, skip heap tracking
    if (Object.keys(eventName).length !== 0) {
      const eventNameToTrack = formatHeapEvent(eventName)
      heapAPI?.track(eventNameToTrack, eventProperties)
    }
    handleOnClick(event)
  }

  return (
    <button
      className={`${buttonType} ${additionalClasses}`}
      onClick={onClickHandler}
      disabled={disabled}
      style={style}
      id={buttonId}
      data-testid={testId}
    >
      {buttonText}
      {children}
    </button>
  )
}

Button.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonType: PropTypes.string,
  handleOnClick: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  additionalClasses: PropTypes.string,
  disabled: PropTypes.bool,
  buttonId: PropTypes.string,
  eventName: PropTypes.object,
  eventProperties: PropTypes.object,
  testId: PropTypes.string,
}

Button.defaultProps = {
  buttonText: "",
  buttonType: "",
  handleOnClick: () => {},
  additionalClasses: "",
  disabled: false,
  buttonId: "",
  eventName: {},
  eventProperties: {},
  testId: "",
}

export default Button
