export function compareValues(key) {
  return function innerSort(a, b) {
    let comparison = 0
    const varA = a[key]
    const varB = b[key]

    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }

    return comparison * -1
  }
}

export function cutOffString(stringToEvaluate, cap) {
  return stringToEvaluate.length > cap
    ? stringToEvaluate.substring(0, cap) + "..."
    : stringToEvaluate
}

export const dropDownStyles = {
  control: (styles) => ({
    ...styles,
    boxShadow: "none",
    borderRadius: "0.5rem",
    paddingLeft: "4px",
    paddingRight: "4px",
    borderColor: "#9A9A9A", // Grey Darker
    "&:hover": {
      borderColor: "#616161", // Grey Darker
    },
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "#9A9A9A", // Grey
    lineHeight: "1.25rem",
    fontWeight: "400",
  }),
  menu: (base) => ({
    ...base,
    border: "1px solid #9A9A9A", // Grey
    borderRadius: "0.5rem",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 7,
    paddingBottom: 7,
    color: "#000000",
  }),
}

export const dropDownColors = {
  primary50: "#5C9F9A",
  primary25: "#CEE8E1",
  primary: "#0E7874",
}

export function findByPrismicId(obj, topicPrismicId) {
  let result
  for (var p in obj) {
    if (obj.prismic_id === topicPrismicId) {
      return obj
    } else {
      if (typeof obj[p] === "object") {
        result = findByPrismicId(obj[p], topicPrismicId)
        if (result) {
          return result
        }
      }
    }
  }
  return result
}

// Format the eventName to be tracked into a string to be sent to Heap
export const formatHeapEvent = (heapEvent) => {
  return heapEvent.eventDomain.concat(
    " - ",
    heapEvent.eventLocation
      .concat(" - ", heapEvent.eventAction)
      .concat(" - ", heapEvent.eventActionName)
  )
}

export const relationshipOptions = [
  {
    value: "parent",
    label: "Parent",
  },
  { value: "sibling", label: "Sibling" },
  {
    value: "friend",
    label: "Friend",
  },
  {
    value: "family member",
    label: "Family member",
  },
  {
    value: "other",
    label: "Other",
  },
]

export function debounce(fn, delay) {
  let handle = null

  return function () {
    if (handle) {
      handle = clearTimeout(handle)
    }

    handle = setTimeout(() => {
      fn(...arguments)
    }, delay)
  }
}

export function seoKeywords(data) {
  return data ?? data?.split(",")
}
