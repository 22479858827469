import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { linkResolver } from "../utils/link-resolver"

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!"

  return (
    <Layout>
      <div className="max-w-body mx-auto pt-8 px-4 pb-8 xl:px-0">
        <p>Loading Preview...</p>
      </div>
    </Layout>
  )
}

PreviewPage.propTypes = {
  isPreview: PropTypes.bool,
}

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
